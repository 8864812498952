import React from "react";
import { InputProps } from "interface/interface";
import styles from "./Input.module.scss";

const Input = ({
  placeholder,
  afterIcon,
  preLabel,
  readOnly,
  type = "text",
  label = "",
  errorMessage = "",
  register,
  inputName,
  textLimit,
  singleInput,
  showCharLeft=false,
  smallInput,
  required= true
}: InputProps) => {
  return (
    <div className={`flex-wrap ${styles.input} ${type==="checkbox" && styles.checkbox} ${smallInput && styles.smallInput} ${singleInput && styles.singleInput} ${preLabel && styles.prLabelInput}`}>
     {type !== "checkbox" && <label> {label} </label>}
      {type !== "textarea" &&  type !== "checkbox" &&(
        <div className={`${styles.inputWrapper} ${preLabel && styles.prLabelWrapper}`}>
          {preLabel && <div className={`flex-wrap ${styles.prLabel}`}>{preLabel}</div>}
          <input readOnly={readOnly} type={type} placeholder={placeholder} {...register(inputName, { required: required })} />
          {afterIcon && <img className={` ${styles.afterIcon}`} src={afterIcon} alt="icon" />}
        </div>
      )}
      {type === "textarea" && (
        <>
          <textarea  placeholder={placeholder} {...register(inputName, { required: required })}></textarea>
          {showCharLeft && <div className={`flex-wrap ${styles.belowText}`}>{textLimit} characters left</div>}
        </>
      )}
       {type === "checkbox" && (
        <>
          <label>
          <input readOnly={readOnly} type={type} placeholder={placeholder} {...register(inputName, { required: required })} />
            <span className={styles.checkmark}></span>
            {label}
        </label>
        
        
        </>
      )}
      <div className={` ${styles.error}`}>{errorMessage}</div>
    </div>
  );
};

export default Input;
