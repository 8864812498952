import { CircularProgress } from "@mui/material";
import { CustomButtonProps } from "interface/interface";
import React from "react";

const CustomButton = ({
  buttonText,
  buttonImg,
  className,
  loading = false,
  handleFunc,
  type = "button",
  disabled = false,
  buttonIcon,
  id = "",
}: CustomButtonProps) => {
  return (
    <button id={id} type={type} className={`button ${className}`} disabled={disabled} onClick={handleFunc && handleFunc}>
      {buttonImg && <img src={buttonImg} alt="icon" />}
      {buttonIcon && buttonIcon}
      {buttonText}
      {disabled && loading && <CircularProgress size={15} />}
    </button>
  );
};

export default CustomButton;
