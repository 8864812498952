
import { useSelector } from 'react-redux';
import styles from './CustomGroup.module.scss';
import React  from 'react';
import { RootState } from 'store/store';
import NoDataPlaceHolder from 'component/NoDataPlaceHolder';
// import AnalyticsCard from 'atoms/analyticsCard/AnalyticsCard';
import noScanImg from "../../assets/images/bubble-no-scan.svg"
import CustomTableMui from 'component/customTableMui/CustomTableMui';
import { customScanHeadCell } from 'component/customTableMui/tableData';
import { MUI_TABLE_TYPE, ROUTES } from 'lib/Constants';
import { useDispatch } from 'react-redux';
import { setSelectedValue } from 'store/custom/reducer';
import { useNavigate } from 'react-router-dom';
// import Pagination from 'component/pagination/Pagination';


// interface CustomGroupProps{
//     setPageNo:(a:number)=>void;
//     pageNo:number;

// }
const CustomGroup = () => {
    const {customFitData} = useSelector((state:RootState)=>state.custom)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const handelSelectCustom=(row:Record<string,any>)=>{
       dispatch(setSelectedValue(row))
       navigate(ROUTES.CUSTOM_DETAILS)
    }
  return (
    <>
    <div className={`flex-wrap ${styles.customGroup}`}>
       
      {/* <div className={` ${styles.customGroup__card}`}>
        <AnalyticsCard customUi title="Customers measured" value={customFitData?.scans?.length ?? 0} />
      </div>
      <div className={` ${styles.customGroup__card}`}>
        <AnalyticsCard customUi title="Time saved" value={`${(customFitData?.scans?.length ?? 0) * 30} mins`} />
      </div> */}
      <div className={` table-group ${customFitData?.scans?.length > 0 ? `` : styles.noShadow} ${styles.customGroup__table}`}>
        {customFitData?.scans?.length > 0 ? (
          <>
            <CustomTableMui  rows={customFitData?.scans ?? []} handleSelectedCustom={handelSelectCustom}  columns={customScanHeadCell} type={MUI_TABLE_TYPE.CUSTOM_LIST} />
            {/* <Pagination  setPageNo={setPageNo} pageNo={pageNo} pageSize={10} data={customFitData?.scans ?? []} /> */}
          </>
        ) : (
          <div className={`${styles.customGroup__noData}`}>
            <NoDataPlaceHolder imgUrl={noScanImg} title="No Scans Found" message="Share the link to gather data" />
          </div>
        )}
      </div>
    </div>
    </>
  );
};

export default CustomGroup;
