import Sidebar from "../sidebar/Sidebar";
import styles from "./Dashboard.module.scss";
import { DashboardProps } from "../../interface/interface";
import syncIcon from "../../assets/images/refresh-cw-05.svg";
import closeIcon from "../../assets/images/x-close.svg";
import { Link, useLocation } from "react-router-dom";
import { REACT_APP_FITVIEW_URL, ROUTES } from "lib/Constants";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { Tooltip } from "@mui/material";
import { copyToClipboard } from "lib/utils";
import { CopyAll } from "@mui/icons-material";

const DashboardWrapper = ({ title, children, sync, close, routes = ROUTES.HOME }: DashboardProps) => {
	const { userDetails, hasCustomAddon } = useSelector((state: RootState) => state.auth);
	const { selectedValue } = useSelector((state: RootState) => state.custom);
	const { pathname } = useLocation();

	return (
		<>
			<div className={` flex-wrap ${styles.dashboard}`}>
				<Sidebar />
				<div className={`${styles.dashboard__right}`}>
					<div className={`${styles.dashboard__header} flex-wrap align-center justify-between`}>
						<h1>
							{hasCustomAddon && pathname === ROUTES.CUSTOM_DETAILS ? selectedValue?.profile?.userName ?? "-" : title}
							{userDetails?.store_name && ` (${userDetails?.store_name})`}
							{userDetails?.hasCustomAddon && userDetails?.isTrialDemo && <Tooltip title={`${REACT_APP_FITVIEW_URL}/custom/${userDetails?.store_url}`} placement="bottom-start">
													
														<p className={` ${styles.appNotInstalled} ${styles.blue}`}>
															<CopyAll />
															<span id="copy-store-url" onClick={() => copyToClipboard(`${REACT_APP_FITVIEW_URL}/custom/${userDetails?.store_url}`, "copy-store-url", "Copied", "Copy store url")}>
																Copy store url
															</span>
														</p>
													
												</Tooltip>}
							{sync && (
								<span className={`${styles.dashboard__sync}`}>
									<img src={syncIcon} alt="sync" /> Sync
								</span>
							)}
						</h1>
						{close && (
							<Link to={routes}>
								<span role="button">
									<img src={closeIcon} alt="close" />
								</span>
							</Link>
						)}
					</div>
					<div className={`${styles.dashboard__content}`}>{children}</div>
				</div>
			</div>
		</>
	);
};
export default DashboardWrapper;
